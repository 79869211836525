.wrapper {
  display: flex;
  gap: var(--spacing-medium);

  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .description {
    font-size: 14px;
    font-weight: 500;
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
}