.wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-medium, 16px);
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  scroll-behavior: smooth;
  padding: var(--spacing-medium, 16px) 0 0;

  :global(.react-codemirror2) {
    height: 100%;

    :global(.CodeMirror) {
      height: 100%;
    }
  }

  :global(.lsf-configure__preview) {
    height: 100%;
  }

  .templatesHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-tightest, 2px);
    align-self: stretch;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-tight, 8px);
    align-self: stretch;
    margin: 0;
    text-align: center;
    color: var(--color-neutral-inverted-content, #F9F8F6);

    /* headline/small */
    font-family: var(--font-family-headings, Figtree);
    font-size: var(--font-size-headline-small, 24px);
    font-style: normal;
    font-weight: var(--font-weight-medium, 500);
    line-height: var(--line-height-headline-small, 32px); /* 133.333% */
    letter-spacing: var(--letter-spacing-dense, -0.1px);

  }

  .description {
    margin-bottom: 0;
  }

  .flexRow {
    display: flex;
    gap: var(--spacing-large, 24px);
    flex-wrap: wrap;

    :global(.lsf-templates-list__template) {
      display: flex;
      min-width: 266px;
      max-width: 290px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid var(--color-neutral-border, #E1DED5);
      background: var(--color-neutral-background, #FDFDFC);
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%);

      img {
        width: 100%;
      }
    }
  }

  .group {
    display: flex;
    padding: var(--spacing-wide, 24px) var(--spacing-wider, 32px) var(--spacing-wider, 32px) var(--spacing-wider, 32px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-x-loose, 32px);
    align-self: stretch;
    border-radius: var(--corder-radius-small, 8px);
    border: 1px solid var(--color-neutral-border, #E1DED5);
    background: var(--color-neutral-surface, #F9F8F6);
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%) inset;

    h3 {
      margin-bottom: var(--spacing-tight, 8px);
    }
  }

  .askAI {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium, 16px);
    border-radius: var(--corder-radius-small, 8px);
    background: linear-gradient(109deg, #FFA663 0%, #FF7557 51.56%, #E37BD3 100%), #FFF;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%) inset;
    padding: var(--spacing-large, 24px) 0;

    .askAIContent {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-tight, 8px);
      margin: 0 auto;
      max-width: 820px;
      width: 60%;

      .description {
        color: var(--color-neutral-inverted-content, #F9F8F6);
        text-align: center;
      }

      .autoMaxTextBoxWrapper {
        border-radius: var(--radius-small, 8px);
        border: 1px solid var(--color-neutral-border, #E1DED5);
        background: var(--color-neutral-surface, #F9F8F6);
        padding: var(--spacing-tight, 8px);

        :global(.lsf-textarea-ls) {
          border: 0 none;
          box-shadow: none !important;
        }

        &.isFocused {
          box-shadow: 0 0 0 4px var(--grape_100), inset 0 -1px 0 rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 15%), inset 0 0 0 1px var(--grape_100);
        }
      }
    }
  }

}