.root {
  padding: 0 var(--spacing-medium) var(--spacing-medium);
  flex: 1;
  overflow-y: auto;
}

.expand {
  border: none;
  display: inline-flex;
  background: transparent;
  width: fit-content;
  align-items: center;
  gap: var(--spacing-tight);
  color: var(--sand_800);
  cursor: pointer;
  user-select: none;
}

.expand:disabled {
  background: transparent;
  color: var(--surface-text-muted)
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: var(--spacing-medium);
}

.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  background: var(--sand_0);
  border: 1px solid var(--sand_300);
  border-radius: 8px;
  transition: opacity ease-in-out 0.25s;
  max-height: fit-content;
  z-index: 1;
}

.card-title {
  padding: 10px 16px 0;
  padding-top: var(--spacing-medium);
  color: var(--colors-surface-icon-on-base, #434343);
  max-height: fit-content;

  /* title/large */
  font-family: var(--typography-font-family-headings, Figtree);
  font-size: var(--typography-font-size-headings-large, 22px);
  font-style: normal;
  font-weight: var(--typography-font-weight-medium, 500);
  line-height: var(--typography-line-height-tall, 28px); /* 127.273% */
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-line-height-medium);
}

.error {
  background: var(--danger_color--faded);
  border: 1px solid var(--danger_color);
  color: var(--danger_color);
  border-radius: 8px;
  padding: var(--spacing-medium)
}
