.modelForm {
  flex: 1;

  .form--raw {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
        grid-template-columns: minmax(0, 1fr);
  }

  &__code {
    :global {
      .cm-editor {
        border-radius: 0 0 5px 5px;
      }
    }
  }

  &__row {
    height: 100%;
  }

  &__promptLabel {
    flex: 1;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
        grid-template-columns: minmax(0, 1fr);
    height: 100%;

    .label-ls--raw {
      &__text{
        align-items: end;
        height: 30px;
      }

      &__field {
        flex: 1;
        flex-direction: column;
        padding-bottom: 8px;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        grid-template-columns: minmax(0, 1fr);

        textarea {
          flex: 1;
          resize: none;
        }
      }

    }
  }

}

.baseModelOption {
  display: contents;

  &__endpoint {
    color: var(--surface-text-dimmer);
  }

  &__providerName {
    width: 100%;
    white-space: nowrap;
  }
}
