/**
* Main block wrapper
*/
.modelWrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px
}

/**
* Form fields wrapper
*/
.modelFields{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modelPreview {
  max-height: 343px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  overflow: auto;
  padding: 16px;
  border-radius: var(--border-radius-large, 8px);
  border: 1px solid var(--color-surface-border, #E1DED5);
  background: var(--color-surface-bold, #F9F8F6);
}


.modelPreviewEmpty {
  flex-direction: column;
  gap: 16px;
  min-height: 343px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-large, 8px);
  border: 1px solid var(--color-surface-border, #E1DED5);
  background: var(--color-surface-bold, #F9F8F6);
  text-align: center;
  padding: 0 60px;
}

.fieldsContainers {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.fieldContainer {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0.7;
  margin-bottom: 8px;
}

.fieldContent {
  margin-top: 8px;
  padding: 12px;
  border-radius: var(--border-radius-medium, 6px);
  border: 1px solid var(--color-surface-border, #E1DED5);
  background: var(--color-surface-bold, #F9F8F6);
  transition: all 0.3s ease;
  min-height: 50px;
}

.fieldsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fieldsBadges {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.fieldsPlaceholder {
  color: var(--color-text-tertiary, #888);
  font-style: italic;
  text-align: center;
  padding: 8px 0;
}

.noFields {
  color: var(--color-text-tertiary, #888);
  font-style: italic;
}

.outputFieldsSection {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
