.block {
  display: flex;
  align-items: center;
  gap: var(--spacing-wide, 24px);
}

.item {
  display: flex;
  align-items: center;
  gap: var(--spacing-tight, 8px);
}

.value {
  -webkit-font-smoothing: antialiased;
  color: var(--color-neutral-content, #262522);

  /* title/large */
  font-family: var(--font-family-headings, Figtree), serif;
  font-size: var(--font-size-title-large, 22px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-title-large, 28px); /* 127.273% */
  letter-spacing: var(--letter-spacing-base, 0);
}

.title {
  -webkit-font-smoothing: antialiased;
  max-width: min-content;
  color: var(--color-neutral-content-subtle, #45433E);

  /* title/small */
  font-family: var(--font-family-headings, Figtree), serif;
  font-size: var(--font-size-title-small, 14px);
  font-style: normal;
  font-weight: var(--font-weight-medium, 500);
  line-height: var(--line-height-title-small, 20px); /* 142.857% */
  letter-spacing: var(--letter-spacing-wide, 0.15px);
}
