.promptAutoRefinement {
  pointer-events: none;

  .button-ls--raw {
    --button-border: 1px solid var(--misc-full-gradient, #FFA663);
    --button-color: var(--color-surface-on-bold, #262522);
    --button-height: 30px;
    --button-background-color: transparent;
    --button-background-image: linear-gradient(109deg, rgb(255 166 99 / 10%) 0%, rgb(255 117 87 / 10%) 51.56%, rgb(227 123 211 / 10%) 100%);
    --button-font-size: var(--ls-font-size-300, 12px);
    --icon-size: 24px;

    text-shadow: 0 1px 0 rgb(18 17 13 / 8%);
    pointer-events: fill;
  }
}

.promptAutoRefinementDialog  {
  .modal-ls--raw {
    &__body {
      padding: 0;
    }
  }

  &__body {
    padding: 48px;
    gap: 48px
  }

  &__bodyGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 24px;
    font-family: var(--typography-font-family-headings, Figtree);
    font-style: normal;

    h1 {
      font-family: var(--typography-font-family-headings, Figtree);
      font-size: var(--typography-font-size-headings-xxxlarge, 32px);
      font-weight: var(--typography-font-weight-medium, 500);
      line-height: 40px;
    }


    h3 {
      color: var(--color-primary-bold, #4C5FA9);

      /* headline/small */
      font-size: var(--typography-font-size-headings-xlarge, 24px);
      font-weight: var(--typography-font-weight-medium, 500);
      line-height: var(--typography-line-height-taller, 32px); /* 133.333% */
    }

    h5 {
      color: var(--color-surface-on-background, #45433E);

      /* body/regular */
      font-size: var(--typography-font-size-body-regular, 16px);
      font-weight: var(--typography-font-weight-regular, 400);
      line-height: var(--typography-line-height-regular, 24px); /* 150% */
      letter-spacing: var(--typography-letter-spacing-widest, 0.5px);
    }

    p {
      color: var(--color-surface-on-background, #45433E);

      /* body/smalle37kr */
      font-size: var(--typography-font-size-body-small, 12px);
      font-weight: var(--typography-font-weight-regular, 400);
      line-height: var(--typography-line-height-smaller, 16px); /* 133.333% */
      letter-spacing: var(--typography-letter-spacing-widest, 0.5px);
    }
  }

  &__right {
    display: grid;
    grid-auto-rows: min-content;
    gap: 24px;

    p.info {
      color: var(--color-surface-on-background, #45433E);

      /* body/smalle37kr */
      font-size: var(--typography-font-size-body-small, 12px);
      font-weight: var(--typography-font-weight-regular, 400);
      line-height: var(--typography-line-height-smaller, 16px); /* 133.333% */
      letter-spacing: var(--typography-letter-spacing-widest, 0.5px);
    }
  }

  &__subsetName {
    height: 40px;
    display: flex;
    align-items: center;
  }

  &__enhanceRun.button-ls--raw {
    --icon-size: 24px;

    text-shadow: 0 1px 0 rgb(18 17 13 / 8%);

    // for some reasons font size doesn't mach with one set in Figma
    // TODO: fix the font-size and button's layout to match with the designs
    width: 171px;
  }

  &__enhanceRunWrapper {
    display: contents;

    .button-ls--raw:not(.button-ls_waiting--raw) {
      --icon-size: 24px;
      --button-border: 1px solid var(--misc-full-gradient, #FFA663);
      --button-color: var(--color-surface-on-bold, #262522);
      --button-background-color: transparent;
      --button-background-image: linear-gradient(109deg, rgb(255 166 99 / 10%) 0%, rgb(255 117 87 / 10%) 51.56%, rgb(227 123 211 / 10%) 100%);
    }
  }

  &__status {
    padding: 2px 0 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    &_inProgress {
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }

    &__icon {
      width: 42px;
      height: 42px;
      flex-shrink: 0;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      color: var(--color-surface-on-bold, #262522);

      /* body/regular */
      font-family: var(--typography-font-family-body, Figtree);
      font-size: var(--typography-font-size-body-regular, 16px);
      font-weight: var(--typography-font-weight-regular, 400);
      line-height: var(--typography-line-height-regular, 24px);
      letter-spacing: var(--typography-letter-spacing-widest, 0.5px);

      span {
        color: var(--color-surface-on-bold, #262522);

        /* headline/large */
        font-size: var(--typography-font-size-headings-xxxlarge, 32px);
        font-weight: var(--typography-font-weight-medium, 500);
        line-height: 40px; /* 125% */
      }
    }
  }

  &__errorIcon {
    color: var(--color-negative-bold)
  }

  &__prompt {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--color-border-light, #E0E0E0);
    border-radius: 8px;
    padding: 16px;
    background-color: var(--color-surface-light, #fdfbfb);

    &::before {
      content: attr(data-title);
      position: absolute;
      top: -10px;
      left: 10px;
      padding: 0 5px;
      background-color: var(--color-surface-light, #fdfbfb);
      color: var(--color-text-secondary, #999);
      font-size: 12px;
      line-height: 1;
    }
  }

  &__reasoning {
    white-space: pre-wrap;
    background-color: var(--color-surface-light, #fdfbfb);
    border: 1px solid var(--color-border-light, #E0E0E0);
    border-radius: 8px;
    padding: 6px 12px 12px;
    margin: 16px 0;

    > * {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h3 {
      margin-top: 0;
    }

    color: var(--color-surface-on-bold, #262522);

    &__description {
      max-height: 200px;
      overflow: auto;
    }

    &__totalCost {
      font-size: var(--typography-font-size-body-regular, 16px);
      font-weight: var(--typography-font-weight-medium, 500);
      line-height: var(--typography-line-height-regular, 24px);
      color: var(--color-surface-on-bold, #262522);
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    background-color: var(--color-surface-light, #F3F3F3);
    border-top: 1px solid var(--color-border-light, #E0E0E0);
    font-family: var(--typography-font-family-body, Figtree);
    font-size: var(--typography-font-size-body-regular, 16px);
    font-weight: var(--typography-font-weight-medium, 500);
    line-height: var(--typography-line-height-regular, 24px);
    color: var(--color-surface-on-bold, #262522);
  }

  &__promptsWrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin: 16px 0;
    max-height: 250px;

    > * {
      flex: 1;
      width: 50%;
    }
  }
}

.refinedPrompt {
  display: block;
  white-space: pre-wrap;
  max-height: 100%;
  overflow: auto;
  color: var(--color-surface-on-bold, #262522);
}
