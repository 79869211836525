.pluginsTestingPanel {
  :global(.lsf-textarea-ls) {
    height: 150px;
  }
}

:global(.lsf-configure__editor_dynamic) {
  .pluginsTestingPanel {
    padding: 1rem;
  }
}
