.banner {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 2px solid #FFA663;
    margin: 16px 20px;

    .content {
        display: flex;
        flex: 1 0 0;
        gap: 8px;
        align-items: flex-start;

        .icon-frame {
            width: 40px;
            padding: 2px 0;
        }

        .text-frame {   
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            flex: 1 0 0;

            .text-title,
            .text-body {
                align-self: stretch;
                color: #262522;
            }

            .text-title {
                font-size: 16px;
                line-height: 24px;
            }

            .text-body {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.15px;
            }
        }

        .controls-frame {
            display: flex;
            justify-content: center;
            gap: 16px;
        }
    }
}