.autoMax {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--spacing-base, 16px);
  flex: 1;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 5px 0;
    color: var(--color-neutral-content, #262522);
    margin-bottom: -10px;

    /* label/medium */
    font-family: var(--font-family-body, Figtree);
    font-size: var(--font-size-label-medium, 16px);
    font-style: normal;
    font-weight: var(--font-weight-medium, 500);
    line-height: var(--line-height-label-medium, 24px); /* 150% */
    letter-spacing: var(--typography-letter-spacing-wide, 0.15px);

    .titleIcon {
      display: inline-flex;
      color: #617ADA;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  &.hasWrapper {
    border: 1px solid var(--colors-surface-border);
    padding: var(--spacing-medium, 16px);
    border-radius: var(--radius-small);

    .userInteractionContainer {
      padding: var(--spacing-tight, 8px);
      background: var(--color-neutral-surface, #F9F8F6);

      /* shadow/input/inset */
      box-shadow: 0 1px 2px 1px rgb(0 0 0 / 6%) inset;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-medium);

}

.chatboxInput {
  width: 100%;
  border: 0 none;
  background-color: transparent;
}

.userInteractionContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  gap: var(--spacing-tight, 8px);
  border-radius: var(--corder-radius-smaller, 4px);
}

.instructions {
  display: flex;
  gap: var(--spacing-small);
  align-items: center;
  margin: 0;

  .icon {
    margin: 0;
  }
}

.icon {
  margin-right: var(--spacing-small);
  display: flex;
  align-items: center;
  margin-left: calc(-1 * var(--spacing-small));

  &_isInProgress {
    color: var(--danger_color);
  }
}

.generateButton {
  &.hide {
    display: none;

    &.isOnlyButton {
      visibility: hidden;
      display: flex;
    }
  }
}

.error {
  &__title {
    font-weight: 500;
    font-size: var(--font-size-large, 1rem);
  }
}

.info {
  display: flex;
  align-items: center;
  gap: var(--spacing-tight, 8px);

  /* body/small */
  font-family: var(--font-family-body, Figtree);
  font-size: var(--font-size-body-small, 14px);
  font-style: normal;
  font-weight: var(--font-weight-regular, 400);
  line-height: var(--line-height-body-small, 18px); /* 128.571% */
  letter-spacing: var(--letter-spacing-wider, 0.25px);

  .infoIcon {
    display: inline-flex;
    color: #6B6860;
  }
}

.emptyChatWrapper {
  display: flex;
  flex: 1;
  padding: 2px;
  background: linear-gradient(109deg, rgb(255 166 99 / 100%) 0%, rgb(255 117 87 / 100%) 51.56%, rgb(227 123 211 / 100%) 100%);
  border-radius: var(--radius-small, 8px);

  .emptyChat {
    display: flex;
    padding: var(--spacing-wide, 24px) var(--spacing-widest, 40px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-base, 16px);
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--corder-radius-small, 8px);
    background: rgb(255 255 255 / 90%);

    .container {
      display: flex;
      padding: var(--spacing-wide, 24px) 0;
      max-width: 560px;
      margin: 0 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-base, 16px);
      flex: 1 0 0;
      align-self: stretch;
      text-align: center;
      color: var(--color-neutral-content-subtle, #45433E);

      .iconWrapper {
        display: flex;
        width: 40px;
        height: 40px;
        padding: var(--spacing-none, 0) 0;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: var(--corder-radius-largest, 24px);
        background: linear-gradient(109deg, #FFA663 0%, #FF7557 51.56%, #E37BD3 100%), var(--color-accent-grape-subtle, #D4DBFB);

        .icon {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          color: var(--color-neutral-inverted-content, #F9F8F6);
        }
      }

      .bigText {
        align-self: stretch;
        color: var(--color-neutral-content, #262522);

        /* title/large */
        font-size: var(--font-size-title-large, 22px);
        font-weight: var(--font-weight-medium, 500);
        line-height: var(--line-height-title-large, 28px); /* 127.273% */
        letter-spacing: var(--letter-spacing-base, 0);
      }

      .mediumText {
        align-self: stretch;

        /* body/medium */
        font-size: var(--font-size-body-medium, 16px);
        font-weight: var(--font-weight-regular, 400);
        line-height: var(--line-height-body-medium, 24px); /* 150% */
        letter-spacing: var(--letter-spacing-base, 0);
      }

      .smallText {
        align-self: stretch;

        /* label/small */
        font-size: var(--font-size-label-small, 14px);
        font-weight: var(--font-weight-medium, 500);
        line-height: var(--line-height-label-small, 18px); /* 128.571% */
        letter-spacing: var(--letter-spacing-wide, 0.15px);
      }

      .smallList {
        text-align: left;
        font-weight: var(--font-weight-regular, 400);
        max-width: 360px;
        margin: 0 auto;
      }
    }
  }
}

.buttonContainer {
  display: flex;
  flex-shrink: 0;
  gap: var(--spacing-small, 8px);
}