.askAI {
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    display: flex;
    width: 28px;
    height: 28px;
    flex: none;
    border-radius: 100px;
    background: linear-gradient(109deg, #FFA663 0%, #FF7557 51.56%, #E37BD3 100%);
    padding: 0;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    .iconWrapper {
      display: flex;
      color: var(--white, #FFF);
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
    }
  }

  .badge {
    display: flex;
    width: 12px;
    height: 12px;
    padding: 0 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: var(--corder-radius-small, 8px);
    background: var(--color-primary-surface, #4C5FA9);
    color: var(--white, #FFF);
    text-align: center;
    font-size: var(--font-size-label-tiny, 9px);
    font-style: normal;
    font-weight: var(--font-weight-medium, 500);
    line-height: var(--line-height-label-smallest, 16px); /* 177.778% */
    letter-spacing: var(--typography-letter-spacing-widest, 0.5px);
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .iconWrapper{
    border-radius: 10em;
  }
}

.askAIModal{
  width: auto;
  height: auto;
  position: fixed;
  background-color: transparent;
  inset: calc(var(--header-height) + 10px) 10px 10px calc(100% - 470px);
  z-index: 50;

  :global(.lsf-modal-ls__wrapper) {
    margin: 0;
    padding: 0;
    height: 100%;
    border: 1px solid var(--border-color, #e0e0e0);
    border-radius: var(--radius, 8px);

    :global(.lsf-modal-ls__content) {
      width: 100%;
      min-width: 0;
      height: 100%;
    }
  }


  &.modal {
    left: 20vw;
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: auto;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-small, 8px);
    align-items: center;
    padding: var(--spacing-x-small, 4px) var(--spacing-small, 8px);

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      transition: background-color 0.3s;

      &:hover {
        background-color: var(--hover-color, #f0f0f0);
      }
    }
  }

  .content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small, 8px);
    flex: 1;
    padding: var(--spacing-medium, 16px);

    :global(.lsf-textarea-ls):not(:global(.lsf-input-ls_ghost), :global(.lsf-.textarea-ls_ghost), :global(.lsf-counter-ls_ghost), :global(.lsf-select-ls__list_ghost)):focus, 
    :global(.lsf-textarea-ls_focused),
    :global(.lsf-button-ls):focus {
      box-shadow: inset 0 0 0 4px var(--grape_100), inset 0 -1px 0 rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 15%), inset 0 0 0 1px var(--grape_100);
    }
  }
}


.emptyChatWrapper {
  display: flex;
  flex: 1;
  padding: 2px;
  border-radius: var(--radius-small, 8px);

  .emptyChat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-base, 16px);
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--corder-radius-small, 8px);
    background: rgb(255 255 255 / 90%);
  
    .container {
      display: flex;
      padding: var(--spacing-wide, 24px) 0;
      max-width: 560px;
      margin: 0 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-base, 16px);
      flex: 1 0 0;
      align-self: stretch;
      text-align: center;
      color: var(--color-neutral-content-subtle, #45433E);
      min-height: 410px;
  
      .iconWrapper {
        display: flex;
        width: 40px;
        height: 40px;
        padding: var(--spacing-none, 0) 0;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: var(--corder-radius-largest, 24px);
        background: linear-gradient(109deg, #FFA663 0%, #FF7557 51.56%, #E37BD3 100%), var(--color-accent-grape-subtle, #D4DBFB);
        box-shadow: 0 0 16px 0 rgb(255 166 99 / 50%), 0 0 48px 0 rgb(255 117 87 / 60%), 0 0 128px 0 rgb(227 123 211 / 50%);
  
        .icon {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          color: var(--color-neutral-inverted-content, #F9F8F6);
        }
      }
  
      .bigText {
        align-self: stretch;
        color: var(--color-neutral-content, #262522);
  
        /* title/large */
        font-size: var(--font-size-title-large, 22px);
        font-weight: var(--font-weight-medium, 500);
        line-height: var(--line-height-title-large, 28px); /* 127.273% */
        letter-spacing: var(--letter-spacing-base, 0);
      }
  
      .mediumText {
        align-self: stretch;
  
        /* body/medium */
        font-size: var(--font-size-body-medium, 16px);
        font-weight: var(--font-weight-regular, 400);
        line-height: var(--line-height-body-medium, 24px); /* 150% */
        letter-spacing: var(--letter-spacing-base, 0);
      }
  
      .smallText {
        align-self: stretch;
  
        /* label/small */
        font-size: var(--font-size-label-small, 14px);
        font-weight: var(--font-weight-medium, 500);
        line-height: var(--line-height-label-small, 18px); /* 128.571% */
        letter-spacing: var(--letter-spacing-wide, 0.15px);
      }
  
      .smallList {
        text-align: left;
        font-weight: var(--font-weight-regular, 400);
        max-width: 360px;
        margin: 0 auto;
      }
    }

    .messageSuggestions {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-small, 8px);

      .messageSuggestion {
        button {
          display: flex;
          width: 388px;
          max-width: 100%;
          gap: var(--spacing-small, 8px);
          justify-content: center;
          align-items: center;
          border-radius: var(--corder-radius-smaller, 4px);
          border: 1px solid var(--color-primary-border, #4C5FA9);
          background: var(--color-neutral-background, #FDFDFC);

          .buttonText {
            text-align: left;
            flex: 1 0 0;
            color: var(--color-primary-content, #4C5FA9);

            /* label/small */
            font-size: var(--font-size-label-small, 14px);
            font-weight: var(--font-weight-medium, 500);
            line-height: var(--line-height-label-small, 18px); /* 128.571% */
            letter-spacing: var(--letter-spacing-wide, 0.15px);
          }
        }
      }
    }
  }
}